@import "@angular/material/prebuilt-themes/indigo-pink.css";
.movil {
  display: none;
}
.desktop {
  display: block;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
select,
textarea,
label,
span,
b,
a,
ul,
li {
  font-family: "Roboto", sans-serif !important;
}
/*Categorias*/
.tituloCategoria {
  color: #44484f;
  text-transform: uppercase;
  font-size: 61px;
  margin-bottom: 3rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
.tituloProducto {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #44484f;
  font-weight: bold;
  &:hover {
    color: #44484f;
    text-decoration: underline;
  }
}
.aProducto {
  color: #44484f;
}
/*Notificaciones*/
.notifier__container * {
  box-sizing: border-box;
}

.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.notifier__notification {
  display: block;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  backface-visibility: hidden;
}

/*# sourceMappingURL=core.css.map */
.notifier__notification--material {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: default;
  padding-top: 11px;
  padding-right: 26px;
  padding-bottom: 10px;
  padding-left: 26px;
}

.notifier__notification--material .notifier__notification-message {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 32px;
  font-size: 15px;
}

.notifier__notification--material .notifier__notification-button {
  display: inline-block;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  margin-right: -10px;
  margin-left: 10px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  width: 32px;
  height: 32px;
  vertical-align: top;
}

.notifier__notification--material .notifier__notification-button:hover,
.notifier__notification--material .notifier__notification-button:focus {
  opacity: 1;
}

.notifier__notification--material .notifier__notification-button:active {
  transform: scale(0.82);
  opacity: 1;
}
.notifier__notification--success {
  background-color: #5cb85c;
  color: #fff;
}

.notifier__notification--success .notifier__notification-button-icon {
  fill: #fff;
}
.notifier__notification--error {
  background-color: #d9534f;
  color: #fff;
}

.notifier__notification--error .notifier__notification-button-icon {
  fill: #fff;
}
/*MENU*/
nav.navbar.navbar-expand-lg.scrolling-navbar {
  background: #fff;
  padding: 0 113px;
}
@media (min-width: 1330px) {
  nav.navbar.navbar-expand-lg.scrolling-navbar {
    background: #fff;
    padding: 0 350px;
  }
}
a.nav-link.waves-effect.waves-light {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.topbar a.nav-link.waves-effect.waves-light {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: 700;
  font-size: 13px;
  color: #666666 !important;
}
.topbar ul {
  float: right;
}
.espMenu {
  margin-top: 23px;
}
.espMenu li {
  padding: 0 11px;
  width: auto;
  margin-top: 8px;
}
.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: absolute;
  height: 149px;
  margin-top: -20px;
}
@media (max-width: 992px) {
  .navbar-brand {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 83px;
  }
  nav.navbar.navbar-expand-lg.scrolling-navbar {
    padding: 0 30px;
  }
  a.nav-link.waves-effect.waves-light {
    text-align: left;
  }
  .dropdown-menu {
    display: block !important;
  }
}
input.buscar {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ddd;
  width: 100%;
  background: url(assets/lupa.png);
  background-repeat: no-repeat;
  background-position: right;
}
input.buscar::placeholder {
  color: #ddd;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.activeMenu,
a.nav-link.waves-effect.waves-light:hover {
  text-decoration: underline;
}
.is-active {
  color: #8c8f92 !important;
}
/*Footer*/
footer.page-footer {
  background-color: #333333 !important;
  margin-top: -16px;
}
footer.page-footer > .container {
  padding: 5% 0;
}
footer.page-footer .footer-copyright > .container-fluid {
  color: #fff !important;
}
ul.menu-foo > li > a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}
ul.menu-foo > li > a:hover {
  text-decoration: underline;
}
ul.menu-foo > li {
  list-style-type: none;
  margin-bottom: 10px;
}
ul.menu-foo {
  padding-left: 0;
}
.titleFoo {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.textFoo {
  font-family: "Roboto", sans-serif;
  color: #fff;
}
.inpFoo {
  border-radius: 25px;
}
.checkText {
  color: #fff;
  font-family: "Roboto", sans-serif;
}
/*Promasters*/
.btn-floating {
  z-index: 999999;
  position: absolute;
  top: 50%;
}
.btn-floating:last-child {
  right: 0;
  float: right;
  margin-right: -3%;
}
.btn-floating:first-child {
  left: 0;
  float: left;
  margin-left: -3%;
}
.carousel-indicators {
  display: none !important;
}
a.btn-floating,
a.btn-floating:hover {
  box-shadow: 0 0 0 transparent;
}
a.btn-floating:active {
  border-color: transparent;
}
.fa-chevron-right,
.fa-chevron-left {
  font-size: 2rem;
}
promasterhome {
  width: 100%;
}
/*A*/
.add {
  position: fixed;
  right: 5%;
  bottom: 10%;
  cursor: pointer;
  width: 4%;
}
.accion {
  cursor: pointer;
}
a.accion {
  color: #212529;
}
.addVideo {
  margin-left: 10%;
  width: 30px;
  cursor: pointer;
  margin-top: -70px;
}
/*B*/
.breadcumbs:hover,
.breadcumbsProducto:hover {
  text-decoration: underline;
}
.breadcumbsProducto,
.breadcumbsProducto:hover {
  color: #44484f;
}
b {
  font-weight: bold;
}
.btnNew {
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 25px;
  position: fixed;
  right: 5%;
  bottom: 10%;
}
.btnNew:hover {
  background: #000;
  color: #fff !important;
}
.btnOrganicBlack {
  background: #000;
  color: #fff;
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 15px;
  margin: 0;
}
.btnOrganicBlack:hover {
  background: #fff;
  color: #000 !important;
  border: 1px solid #000;
}
.btnDonde {
  width: 100%;
}
.btnPro {
  width: 250px;
}
.btnDonde,
.btnPro {
  background: transparent;
  color: #6c6869 !important;
  border: 2px solid #6c6869;
  height: 55px;
  line-height: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-top: 1rem;
}
.btnDonde:hover,
.btnPro:hover {
  background: #6c6869 !important;
  color: #fff !important;
}
.btnComo {
  color: #9a48b6 !important;
  border: 2px solid #9a48b6;
  height: 55px;
  line-height: 35px;
  width: 255px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.btnEvento {
  background: #3d424c;
  color: #fff !important;
  padding: 5% 12%;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  width: 100%;
  border-radius: 0 0 10px 10px;
}
.btnEventos {
  background: #fff;
  color: #6c6869 !important;
  border: 2px solid #6c6869;
  padding: 15px 50px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.btnEventos:hover {
  background: #6c6869;
  color: #fff !important;
}
.btnTransparente {
  background: transparent;
  border: 2px solid #fff;
  padding: 15px 10px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-top: 1rem;
}
.btnTransparente:hover {
  background: #fff;
  color: #000 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #fff !important;
  background: #000;
  border-radius: 30px;
  padding: 3px 8px;
}
.mat-form-field-infix {
  border-top: 0;
  border: 1px solid #d9d9d9;
  width: 100%;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.mat-form-field-appearance-legacy.mat-form-field-disabled
  .mat-form-field-underline {
  background-image: none;
}
.mat-input-element {
  vertical-align: middle;
}
/*C*/
.caracteristica {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 20px;
}
.content-header {
  margin-top: 3%;
  margin-bottom: 3%;
}
#capacitate {
  background: url("assets/home/fondo_como_aprender.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.content-wrapper {
  background-color: #fff;
}
.content-header > .breadcrumb > li + li:before {
  margin-left: 5px;
}
.categoriaNombre {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: #44484f;
  font-size: 23px;
}
.categoriaDescripcion {
  font-family: "Roboto", sans-serif;
  color: #44484f;
  width: 50%;
  margin-left: 25%;
}
/*D*/
#donde {
  background: url("assets/home/mapa.jpg");
  background-position: right;
  background-repeat: no-repeat;
}
.divCentro {
  margin: 0 auto;
}
.divCenter {
  margin: auto;
}
.dnm {
  display: block;
}
.dbm {
  display: none;
}
/*E*/
.esp {
  margin: 70px 0;
}
.espAll {
  margin-bottom: 1rem;
}
.espCentros {
  margin-top: 70px;
}
.espUp {
  margin-top: 30px;
}
.espSecInit {
  margin-top: 10%;
}
.espComo {
  padding: 4% 0;
}
#eventos {
  background: url("assets/home/eventos.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
}
.eventosbottom {
  padding-bottom: 5%;
}
.espForms {
  margin: 10px 0;
}
/*F*/
.formON {
  border-radius: 25px;
}
/*H*/
.hidden-md-up,
.hidden-lg-up {
  display: none;
}
/*I*/
.imgPais {
  height: 54px;
  margin-bottom: 1rem;
}
.imgCalendario {
  margin-top: -10%;
}
.imgGeneral {
  width: 100%;
}
.imgFull {
  max-width: 100%;
}
.itemMenu {
  color: #000;
  font-family: "Roboto", sans-serif;
  vertical-align: sub;
  text-transform: uppercase;
}
.itemMenu:hover {
  color: #000;
  text-decoration: underline;
}
.itemMenu:visited {
  color: #000;
}
.itemMenu.principal {
  font-size: 22px;
  text-decoration: none;
  font-weight: bold;
}
.imgMiniatura {
  width: 50%;
  margin-bottom: 20px;
}
/*L*/
.ligth {
  font-weight: 100;
  font-family: "Roboto", sans-serif;
}
/*M*/
.modal {
  background: rgba(0, 0, 0, 0.8);
}
.modal-content {
  border-radius: 15px;
  min-height: 200px;
}
.modal-header {
  background: #000 !important;
  color: #fff !important;
}
.modal-body {
  padding: 20px !important;
}
.modal-footer {
  border-top: 1px solid #6e6e6e !important;
}
h5.modal-title {
  font-weight: bold !important;
  font-size: 18px;
  float: left;
}
.mat-form-field {
  width: 100%;
}
#menuCatalogos {
  padding: 30px 350px 1% 350px;
  box-shadow: inset 0 -2px 2px 0 #7d7d7d;
}
.marginCentertb {
  margin-top: auto;
  margin-bottom: auto;
}
.marginCenterlr {
  margin-left: auto;
  margin-right: auto;
}
.mbz {
  margin-bottom: 0;
}
/*N*/
/*O*/
ol.breadcrumb {
  vertical-align: middle;
  margin-top: 4%;
  float: right;
  background-color: transparent;
}
.obligatorio {
  color: red;
  font-weight: bold;
}
/*P*/
.pz {
  padding: 0;
}
.pzb {
  padding-bottom: 0 !important;
}
.pzt {
  padding-top: 0 !important;
}
.paddingt45 {
  padding-top: 45px !important;
}
/*S*/
span#status {
  cursor: pointer;
}
span.Error {
  float: right;
  color: red;
  font-size: 10px;
  margin-top: 5px;
}
section {
  padding: 90px;
}
strong {
  font-weight: bold;
}
.skin-black .wrapper,
.skin-black .main-sidebar,
.skin-black .left-side {
  background-color: #000 !important;
}
.skin-black .sidebar a {
  color: #fff !important;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > span:not(.pull-right),
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a,
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > .treeview-menu {
  background-color: #000 !important;
  color: #fff !important;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a:hover
  > span,
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a,
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > .treeview-menu:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}
.subTitulo,
.subTituloBlanco {
  font-family: "Roboto", sans-serif;
}
.subTitulo {
  color: #44484f;
}
.subTituloBlanco {
  color: #fff;
}
/*T*/
.titulo,
.tituloBlanco {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*font-size: 61px;*/
  font-size: 4vw;
  text-transform: uppercase;
}
.tituloExtra {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 45px;
  color: #44484f;
}
.titulo,
.texto {
  color: #44484f;
}
.tituloBlanco,
.textoBlanco {
  color: #fff;
}
.texto,
.textoBlanco {
  font-family: "Roboto", sans-serif;
  font-size: 21px;
}
.textDonde {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 21px;
  color: #44484f;
}
.textPais {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 100%;
  height: 200px;
  color: #44484f;
}
.tabVideos {
  border-bottom: 3px solid #000;
}
/*U*/
ul.sidebar-menu > li > a:hover {
  background: #fff !important;
  color: #000 !important;
  font-weight: bold;
}
.modal-header .close,
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  float: right;
  color: #fff;
}

/*Medies*/
@media (min-width: 768px) {
  nav.navbar.navbar-static-top {
    height: 50px !important;
  }
}
@media (max-width: 991px) {
  .espMenu li {
    width: 100%;
    padding: 0;
  }
  .col-md-12,
  .textoBannerIzq,
  .textoBannerDer {
    text-align: center !important;
  }
  #donde {
    background: none;
  }
  .redes a {
    margin: 20px;
  }
  .detalleContacto {
    display: inline-block !important;
    width: 100%;
  }
  .textContact {
    margin-left: 0 !important;
    margin-top: 1rem !important;
  }
  .pin {
    position: relative !important;
    margin-left: -0px !important;
    margin-bottom: 1rem;
  }
  .numero {
    margin-left: -17px !important;
  }
  .topbar ul {
    float: none;
  }
  .topbar a.nav-link.waves-effect.waves-light {
    font-size: 16px;
    margin-left: 0;
  }
  .titulo,
  .tituloBlanco,
  .recibe {
    font-size: 40px !important;
  }
  .imgBanner {
    width: 50% !important;
  }
  .textPais {
    height: auto;
  }
  .btnDonde {
    width: 250px;
  }
}
@media (max-width: 500px) {
  .btnBannerP {
    width: 230px !important;
  }
}
@media (max-width: 768px) {
  /*A*/
  .add {
    width: 20%;
  }
  /*B*/
  .btn-floating:last-child {
    margin-right: -15%;
  }
  .btn-floating:first-child {
    margin-left: -15%;
  }
  .bannersSec {
    text-align: left !important;
  }
  .btnBannerP {
    display: block;
    margin-left: 20%;
    width: 400px;
    padding: 2% 0 !important;
  }
  /*C*/
  .card {
    margin-top: 10% !important;
  }
  .content-header {
    margin-top: 30%;
    margin-bottom: 3%;
  }
  #capacitate {
    background: url("assets/bannercentros.jpg");
    background-size: cover;
    background-position: bottom;
  }
  /*D*/
  div.pin {
    width: 100% !important;
  }
  .dnm {
    display: none;
  }
  .dbm {
    display: block;
  }
  /*E*/
  #eventos {
    background: none;
    background-size: 100% 100%;
  }
  /*F*/
  .flex {
    display: flex;
    display: -webkit-box;
  }
  .fullmovil {
    width: 100%;
  }
  /*H*/
  .hidden-md-up {
    display: block !important;
  }
  /*I*/
  .imgFullMovil {
    width: 100%;
  }
  .imgCalendario {
    margin-top: -5%;
  }
  .imgBanner {
    width: 75% !important;
  }
  /*L*/
  .linksMovil {
    width: 48%;
    padding: 0 7%;
    text-align: left;
    display: inline-flex;
  }
  .linksMovil > ul > li > a {
    font-size: 13pt;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  /*M*/
  #menuCatalogos {
    padding: 30px;
  }
  /*R*/
  .redesSocialesImg {
    width: 50%;
    margin: 30px 0;
  }
  /*S*/
  section#donde.esp {
    padding: 5% 10% 0px;
  }
  section {
    padding: 45px 10px;
  }
  .subtitulo {
    font-size: 35px !important;
  }
  .slogan {
    font-size: 40px !important;
  }
  /*T*/
  .texto {
    font-size: 20px;
  }
  .textoBannerPrincipalDer {
    text-align: center;
  }
}
@media (max-width: 1199px) {
  .imgPromaster {
    float: right !important;
    width: 70% !important;
  }
}
@media  (max-width: 1688px){
  #menuCatalogos {
    padding: 30px 10px 1% 10px !important;
  }
}
@media (min-width: 1030px) {
  .dropdown-menu {
    left: 25px !important;
  }
}
@media (min-width: 1145px) {
  .dropdown-menu {
    left: 35px !important;
  }
}
@media (min-width: 1280px) {
  .dropdown-menu {
    left: 45px !important;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu {
    left: 55px !important;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu {
    left: 60px !important;
  }
}
@media (min-width: 1550px) {
  .dropdown-menu {
    left: 70px !important;
  }
}
@media (min-width: 1037px) {
  .divCC {
    margin: 0 7% !important;
  }
}
@media (min-width: 1160px) {
  .divCC {
    margin: 0 auto !important;
  }
}
@media (min-width: 597px) {
  .divCC {
    margin: 0 25% !important;
  }
}
@media (min-width: 661px) {
  .divCC {
    margin: 0 auto !important;
  }
}


@media (min-width: 1023px) and (max-width: 1760px){
  nav.navbar.navbar-expand-lg.scrolling-navbar {
    padding: 0 20px;
  }
}
